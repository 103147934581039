  import { Link } from 'react-router-dom';
  import { useNavigate } from 'react-router-dom';
  import { useMediaQuery } from 'react-responsive';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { useEffect, useState } from 'react';
  import { faPenToSquare, faPlusCircle, faX } from '@fortawesome/free-solid-svg-icons';

  import DashboardLoader from './DashboardLoader';

function Dashboard( props ) {

  const [posts, setPosts] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [element, setElement] = useState();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const handleClickDelete = async (id, e) => {
    await fetch(process.env.REACT_APP_POSTS_API_URL + `/${id}`, {method: 'DELETE', headers: { 'x-access-token': user.token }})
      .then((response) => {
        if(!response.ok){ throw new Error('Something went wrong')}
        navigate(0);
      })
      .catch((e) => { console.log(e) })
    }
  
  const handleModal = (e) => {
    setIsVisible(true);
    setElement(e);
  }

  
  const isDesktop = useMediaQuery({ minWidth: 1024 });

const Modal = (e) => {
  return(
    <section className={ isDesktop ? 'w-100 h-100 fixed left-0 top-0 flex justify-center' : 'w-100 h-auto absolute right-0 top-0 flex justify-center'}>
      <div className={ isDesktop ? 'absolute bg-black opacity-50 w-100 h-100' : 'w-100 h-full bg-black fixed flex justify-center'} onClick={() =>  setIsVisible(false)}>
      </div>
    <div className={ isDesktop ? "w-auto h-max text-black bg-white fixed m-36 rounded" : 'w-auto h-max relative top-[250px] text-black z-40 bg-white m-0'}>
      <span className={ isDesktop ? "flex justify-between" : 'flex justify-center relative'}>
      <h3 className='p-3 font-sans font-bold text-red-400 w-max h-min text-md'> Borrar entrada </h3>
        <button onClick={() => setIsVisible(false)}> <FontAwesomeIcon icon={faX} className="p-3 text-blue-400 text-md"/></button>
      </span>
      <hr />
      <div className="p-2 m-3 font-sans text-md">
        <p className='font-sans font-bold'>Estas a punto de borrar la entrada:</p>
        <p className='p-2 font-sans w-80'>Título: <strong className='font-sans underline underline-offset-2'>{element.Entry_Title}</strong>.</p>
        <p className='p-2 font-sans'>Id: <strong className='font-sans underline underline-offset-2'>{element._id}</strong></p>
      </div>
      <span className="flex justify-end p-2">
        <button onClick={() => setIsVisible(false)} className="p-2 m-1 text-white bg-blue-500 hover:bg-blue-700">Cancelar</button>
        <button onClick={() => handleClickDelete(element._id)}className={`text-white bg-red-500 hover:bg-red-800 focus:bg-red-900  ${ isDesktop ? 'p-2 m-1' : 'p-4'}`}>Eliminar</button>
      </span>
    </div>
  </section>
  )
}

  useEffect( () => {
    fetch(process.env.REACT_APP_POSTS_API_URL)
      .then(response => { return response.json()} )
      .then(data => { setPosts(data)})
    .catch(err => {console.log(err)} )
  },[]);


  return (
    <section className={ isDesktop ? `h-full w-100` : 'w-100 relative right-0 top-60'}>
      <div className='flex justify-between'> 
        <h2 className='p-2 text-xl font-bold'>Todas las publicaciones</h2> 
        <button className='p-2 m-2 text-white bg-green-600'>
          <FontAwesomeIcon icon={faPlusCircle}/> Nueva
        </button>
      </div>
      <div className={ isDesktop ? 'flex flex-wrap h-100' : 'flex flex-wrap w-100'}>
        { posts ? posts.reverse().map((e) => <div key={e._id} className={ isDesktop ? "w-25 p-3 h-[300px]" : "w-100 p-4 h-auto m-1"}>
        <div className="flex justify-center h-100" key={e._id} >
          <div className="max-w-sm bg-white rounded-lg shadow-lg">
            <Link to={e._id}>
              <img loading="lazy" className="rounded-t-lg h-50 w-100 " src={e.Entry_Featured_Image} alt={e.Entry_Title + '-img'}/>
            </Link>
            <div className="p-2">
              <h5 className="h-20 p-1 mb-2 font-semibold text-gray-900 text-md">{e.Entry_Title}</h5>
              <div className='flex justify-between'>
                {/* VISIT BUTTON */}
                <span>
                  <Link to={e._id}> <button type="button" className={`inline-block bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out ${isDesktop ? 'px-4 py-2 mr-1' : 'p-3'}`}>Ver entrada</button></Link>
                </span>
                <span>
                  {/* EDIT BUTTON */}
                  <Link to={'edit/' + e._id}><button type="button" className="inline-block px-2 py-2 mr-1 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md  hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"> <FontAwesomeIcon icon={faPenToSquare} className={isDesktop ? '' : 'p-2'}/></button></Link>
                  {/* DELETE BUTTON */}
                  <button onClick={() => handleModal(e)}
                  type="button" className={`inline-block bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-800 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-900 active:shadow-lg transition duration-150 ease-in-out ${isDesktop ? 'px-2 py-2' : 'p-2'}`} data-bs-toggle="modal" data-bs-target="#exampleModal"> <FontAwesomeIcon icon={faX} className={isDesktop ? '' : 'p-2'}/>
                  </button>
                  {isVisible ? <Modal/> : null}
                  {/* ----- */}

                </span>
              </div>
            </div>
          </div>
        </div>
        </div> )

: <DashboardLoader/>}
</div>
    </section>
  )
}

export default Dashboard