import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause, faTowerBroadcast, faCircle, faInfinity } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from './../../assets/bg/1.jpg';
import los40Logo from './../../assets/stream/logo.png';
import './RadioPlayer.css';

export default function RadioPlayer() {

    const music = useRef(new Audio());
    const [ isPlaying, setIsPlaying ] = useState(false);
    const [ musicIndex, setMusicIndex] = useState(0);
    const [ progress, setProgress] = useState(0);
    const [ duration, setDuration ] = useState(0);
    const [ currentTime, setCurrentTime ] = useState(0);

    const songs = [
        {
            path: 'https://frontend.radiohdvivo.com/los40/live?dist=los40-web-live_streaming_play',
            displayName: 'LOS40',
            cover: los40Logo,
            artist: 'EN DIRECTO',
        }
    ];

    useEffect(() => {
        const handleEnded = () => changeMusic(1);
        const handleTimeUpdate = updateProgressBar;

        music.current.addEventListener('ended', handleEnded);
        music.current.addEventListener('timeupdate', handleTimeUpdate);

        loadMusic(songs[musicIndex]);

        return () => {
            music.current.removeEventListener('ended', handleEnded);
            music.current.removeEventListener('timeupdate', handleTimeUpdate)
        };
    }, [musicIndex]);

    const togglePlay = (e) => {
        if(isPlaying) {
            e.preventDefault();
            music.current.pause();
            setIsPlaying(false);
        } else {
            e.preventDefault();
            music.current.play();
            setIsPlaying(true);
        }
    };

    const loadMusic = (song) => {
        music.current.src = song.path;
        document.getElementById('music-title').textContent = song.displayName;
        document.getElementById('music-artist').textContent = song.artist;
        document.getElementById('cover').src = song.cover;
        document.getElementById('bg-img').src = backgroundImage;
    };

    const changeMusic = (direction) => {
        setMusicIndex((prevIndex) => (prevIndex + direction + songs.length) % songs.length);
        loadMusic(songs[musicIndex]);
        if ( isPlaying ) music.current.play();
    };

    const updateProgressBar = () => {
        const {duration, currentTime} = music.current;
        const progressPercent = duration ? (currentTime / duration) * 100 : 0;
        setProgress(progressPercent);
        setCurrentTime(currentTime);
        setDuration(duration);

        const formatTime = (time) => String(Math.floor(time)).padStart(2, '0');

        // document.getElementById('current-time').textContent = `${formatTime(currentTime / 60)}:${formatTime(currentTime % 60)}`;
        // document.getElementById('duration').textContent = `${formatTime(duration / 60)}:${formatTime(duration % 60)}`;
    }

    const setProgressBar = (e) => {
        const width = document.getElementById('player-progress').clientWidth;
        const clickX = e.nativeEvent.offsetX;
        music.current.currentTime = (clickX / width) * music.current.duration;
    }

//* *//
//* *//
//* *//
//* *//
//* *//
//* *//

// function playMusic(){
//     isPlaying = true;

//     // Change play button icon
//     playBtn.classList.replace('fa-play', 'fa-pause');

//     // Set button hover title
//     playBtn.setAttribute('title', 'Pause');
//     music.play();
// }

// function stopMusic(){
//     isPlaying = false;

//     // Change pause button icon
//     playBtn.classList.replace('fa-pause', 'fa-play');

//     // Set button hover title
//     playBtn.setAttribute('title', 'Play');
//     music.pause();
// }

// playBtn.addEventListener('click', togglePlay);
// prevBtn.addEventListener('click', () => changeMusic(-1));
// nextBtn.addEventListener('click', () => changeMusic(1));
// music.addEventListener('ended', () => changeMusic(1));
// music.addEventListener('timeupdate', updateProgressBar);
// playerProgress.addEventListener('click', setProgressBar);

// loadMusic(songs[musicIndex]);

  return (
    <section className='flex justify-center w-full h-screen p-24'>

        <div className="background">
            <img src={backgroundImage} id="bg-img" alt="Background" />
        </div>
        
        <div className='container'>
            <div className="player-img">
                <img src={backgroundImage} className="active"
                id="cover" alt="Cover"/>
            </div>

            <h2 id="music-title" className='fuenteCustom'></h2>
            <h3 id="music-artist" className='text-red-500'></h3>


            <div className="player-progress" id="player-progress" onClick={setProgressBar}>
                <div className="progress" style={{width: `${progress}%` }} id="progress"></div>
                <div className="music-duration">
                    <span id="current-time font-semibold">{`${Math.floor(currentTime / 60)}:${String(Math.floor(currentTime % 60)).padStart(2, '0')}`}</span>
                    {/* <span id="current-time">0:00</span> */}
                    {/* <span id="duration">0:00</span> */}
                    <span id="duration"> {isPlaying ? ( <> 
                        <i className='not-italic uppercase customText'>En directo</i>
                        <FontAwesomeIcon className='px-1 text-red-500 text-md' icon={faCircle} /> </>
                    ) : <FontAwesomeIcon icon={faInfinity} />} 
                    </span>
                </div>
            </div>
                    {/* // ) : (`${Math.floor(duration / 60)}:${String(Math.floor(duration % 60)).padStart(2, '0')}` ) }  */}

            <div className="player-controls">
                <FontAwesomeIcon
                    className='fa-solid'
                    icon={faBackward}
                    title="Previous"
                    onClick={() => changeMusic(-1)}
                />
                <FontAwesomeIcon
                    className='fa-solid'
                    icon={isPlaying ? faPause : faPlay}
                    title="Play"
                    onClick={togglePlay}
                />
                <FontAwesomeIcon
                    className='fa-solid'
                    icon={faForward}
                    title="Next"
                    onClick={() => changeMusic(1)}
                />
            </div>
        </div>
    </section>
  )
}