function BannerAdDos(props) {

  const {imagen} = props;
  return (
    <section className="rounded md:h-40 w-100" id="container">
      <a href="https://www.neuquencapital.gov.ar/">
        <img loading="lazy" src={imagen} className="flex justify-center object-cover h-full m-auto w-100" alt="publicidad"/>
      </a>
      
    </section>
  )
}

export default BannerAdDos