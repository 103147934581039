
// Styles
import './App.css';
import { useEffect, useState } from "react";
import HeaderTest from './components/Home/HeaderTest.jsx';
import GitCarousel from './components/Home/GitCarousel.jsx';
import Footer from './components/Footer/Footer';
import PostsComponent from './components/Home/PostsComponent';
import Widget from "./components/Home/Widget.jsx";
import SectionItem from './components/Home/SectionItem.jsx';
import Policiales from "./components/Containers/Policiales.jsx";
import Deportes from "./components/Containers/Deportes.jsx";
import Tecnologia from "./components/Containers/Tecnologia.jsx";

import axios from "axios";
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import BannerAd from "./components/Ads/BannerAd.jsx";
import BannerAdDos from "./components/Ads/BannerAdDos.jsx";
import loteo from './assets/banners/loteo.gif'
import ASFALTO from './assets/muni2024/BANNER-WEB-ASFALTO-995x200.gif'
import LOTEOS from './assets/muni2024/BANNER-WEB-LOTEOS-995X200.gif'
import PASEO from './assets/muni2024/BANNER-WEB-PASEO-995x200.gif'
import POLO from './assets/muni2024/BANNER-WEB-POLO-995x200.gif'
  /*Imagenes de Publicidades*/
  import Banner from './assets/data/banner.gif';
  import Publicidades from './components/Ads/Publicidades.jsx';
  import newBpn2 from './assets/data/bpn.jpg';
  // import OpenGraph from './components/OpenGraph/OpenGraph.jsx';
  import Advices from './components/Home/Advices/Advices.jsx';
import { Helmet } from 'react-helmet-async';

  function getRandomBanner() {
    const banners = [ASFALTO, LOTEOS, PASEO, POLO];
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  }
  
export default function App () {

  const [cortes, setCortes] = useState([]);

  const [carousel, setCarousel] = useState([]);
  const [ politics, setPolitics ] = useState([]);
  const [ economics, setEconomics ] = useState([]);
  const [ society, setSociety ] = useState([]); 

  /* <===== Scroll to TOP =====> */
  const [showButton, setShowButton] = useState(false);
  
  useEffect( () => {

    const getCortes = async () => {
      const result = await axios.get(process.env.REACT_APP_CORTES_API_URL);
      setCortes(result.data)
    }

    window.addEventListener("scroll", () => { if(window.pageYOffset > 300) { setShowButton(true); } else { setShowButton(false); } })

    const getData = async () => {
      const result = await axios.get(process.env.REACT_APP_CAROUSEL_URI);
      setCarousel(result.data.reverse());
    }

    const getPolitics = async () => {
      const result = await axios.get(process.env.REACT_APP_WIDGET_P);
      setPolitics(result.data);
    }

    const getEconomics = async () => {
      const result = await axios.get(process.env.REACT_APP_WIDGET_E);
      setEconomics(result.data);
    }

    const getSociety = async () => {
      const result = await axios.get(process.env.REACT_APP_WIDGET_S);
      setSociety(result.data);
    }
    
  getPolitics();
  getEconomics();
  getSociety();

    getCortes();
    getData();

  
  },[]);

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
/*News Loader*/


useEffect( () => {
  
}, [])

/*Media Queries*/
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
/*Meta Tags*/
  const title = 'VoxDiario | Portal de Noticias';
  const description = 'Noticias de Neuquén, Río Negro, la Patagonia, Argentina y el mundo en tiempo real. Enteráte antes que nadie con el portal de noticias digital, VoxDiario. Los 40 Neuquén FM 92.3'
  const imageUrl = "https://opengraph.b-cdn.net/production/images/f91a8e89-688e-43b6-8fc4-95129f4b2660.png?token=ZCKldHAh3c2RYaq6W2ELCvYpw31n_SYm203sQqgD-9c&height=751&width=751&expires=33265553864";
  const type = 'website';

 return(
  <>

 {/* <meta charset="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description}/> */}

        {/* <!-- Open Graph / Facebook --> */}
        {/* <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:locale" content="es_AR" />
        <meta property="og:site_name" content="VoxDiario" /> */}

        {/* <!-- Twitter --> */}
        {/* <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={imageUrl} />
        <meta property="twitter:url" content={window.location.href}/>
        <meta name="twitter:site" content="@VoxDiario" />
        <meta name="twitter:creator" content="@VoxDiario" /> */}

  {/* <Provider> */}
    {/*===================  Header && Nav  ==================*/}
    <HeaderTest/>
    {/* <Header/> */}
    {/*===================  Advertisement ===================*/}
      
    {/*======================  Section 1 =====================*/}
      
      {/*======================  Carousel =====================*/}
    <div id="container">
      <Publicidades imagen={Banner} />
      <GitCarousel carousel={carousel}/>
    {/*======================  Section 2 =====================*/}
      <Advices cortes={cortes}/>
      <hr className={isMobile ? 'h-10' : ''}/>

      {/*===================  Advertisement ===================*/}
        <BannerAd/>

    {/*=======================  Widget ======================*/}

      <hr className={isDesktop ? 'h-8' : 'mt-10'}/>
        <Widget politics={politics} economics={economics} society={society}/>
        <hr className={isDesktop ? 'h-8' : 'mb-10'}/>
    </div>

    {/*======================  Section 4 =====================*/}
      <div id="container">
        <BannerAdDos imagen={getRandomBanner()}/>
        <hr className={isDesktop ? 'h-8 mt-10' : 'mt-10 container-md'}/>
      </div>
    {/*======================  Section 5 =====================*/}
      <Policiales />
      <br></br>
      <br></br>
      <Deportes />
      <Tecnologia/>

      <div className="h-48 p-2 my-4 w-100 outline outline-1 outline-black hover:outline-blue-800 container-md" >
      <a href="http://mutualbpn.com.ar/" target="_blank">
        <img loading="lazy" src={newBpn2} className="flex justify-center m-auto h-100 w-62" alt="Publicidad Skin Factory"/>
      </a>
    </div>
      

    <SectionItem />
    {/*=======================  // ======================*/}

    {/* CATEGORÍAS */}
    <Footer/>


    <button
      type="button"
      className={ showButton ? " inline-block w-10 m-1 h-9 animate-bouncing-button bg-purple-500 text-white font-xs hover:bg-purple-700 hover:shadow-lg focus:bg-purple-600 focus:shadow-lg focus:outline-none  active:bg-purple-800 active:shadow-lg transition duration-75 ease-in-out bottom-24 right-1 fixed"
    : "hidden w-9 h-9 bg-purple-500 text-white font-xs hover:bg-purple-700 hover:shadow-lg focus:bg-purple-600 focus:shadow-lg focus:outline-none  active:bg-purple-800 active:shadow-lg transition duration-75 ease-in-out bottom-4 right-1 fixed"}
      id="goTop"
      onClick={handleScroll}>

      <FontAwesomeIcon icon={faChevronUp} className="text-xs" />

    </button>
  {/* </Provider> */}
  </>
 )
};
