import axios from 'axios';
import { Link } from "react-router-dom"
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from "react";

  /*Publicidades*/
  import Contacto from './../../../assets/Calf/contactonqn.png'
  import LaSeptima from '././../../../assets/Calf/LaSeptimaBG.png'
  import ZetaBirra from '././../../../assets/data/zeta.gif'
  import CortesCarousel from "./../../Carousel/CortesCarousel";
  import newBpn from '././../../../assets/data/bpn1.jpg';
  import loteo from '././../../../assets/banners/loteo.gif'

export default function Advices (props) {

  const [posts, setPosts] = useState([]);
  const {cortes} = props;
  
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const mediaQueryValidator = () => {
    if ( isMobile ){
      return "md:w-2/4 h-auto p-3.5 text-blue-600"
    } else if ( isTablet ) {
      return "md:w-2/4 h-auto p-3.5 text-red-500"
    } else if ( isDesktop ) {
      return "container shadow-md p-8 h-auto w-100 my-3 rounded"
    } else {
      return
    }

  };

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get(process.env.REACT_APP_COMPONENT_URI);
      setPosts(result.data.reverse());
    }
    getData();
    
  }, [])

  let content = posts.map((e) => e);
  let reverse = content.slice(2).reverse();
  let arr = [0,1,2,3,4,5];

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }


  return(
  <section className="block mt-4 lg:flex md:shadow-inner">

  <section className="md:w-2/3 block py-1.5 justify-between h-max md:p-4">

    <CortesCarousel cortes={cortes}/>
    
    <br/>

    <div className="h-32 w-100 outline outline-1 outline-black hover:outline-blue-800">
      <a href="https://www.neuquencapital.gov.ar/" target="_blank">
        <img loading="lazy" src={loteo} className="flex justify-center w-full m-auto h-100" alt="Publicidad Municipalidad de Neuquén"/>
      </a>
    </div>
    
    <br/>

    <div className="h-32 w-100">
      <a href="http://mutualbpn.com.ar/" target="_blank" className="outline-black hover:outline-blue-800">
        <img loading="lazy" src={newBpn} className="object-contain h-32 outline outline-1 w-100" alt="Publicidad Mutual BPN"/>
      </a>
    </div>

    <br/>

    <br/>

    <div className="h-32 my-2 bg-black w-100">
      <a href="https://www.instagram.com/zeta.birra/" target="_blank">
        <img loading="lazy" src={ZetaBirra} className="object-contain h-32 outline outline-1 outline-black w-100" alt="Publicidad Zeta Birra"/>
      </a>
    </div>

    <br/>

    <div className="w-100 bg-[#2D2D2D] my-2 outline outline-1 outline-black">
      <a href="https://laseptimaradio.com/" target="_blank">
        <img loading="lazy" src={LaSeptima} className="flex h-32 m-auto" alt="Imagen La Séptima Radio con vos"/>
      </a>
    </div>

  </section>

  <hr className="mt-4"/>

  <section id="item-list-wrap" className="flex-wrap justify-around inline h-auto px-2 md:flex w-100">
      {reverse.length > 0 ? 
      reverse.map((e) => <div className={mediaQueryValidator()} key={e._id}>
        
        <Link to={e.Entry_Category + '/' + e._id}>
          <img
            loading="lazy"
            src={e.Entry_Featured_Image}
            className="saturate-150 object-cover object-top w-100 h-[200px]"
            alt={e.Entry_Title}
            />
            </Link>

        <span className="relative -top-12 float-right right-4 bg-[#604595] font-semibold p- ext-xs text-white">
          {e.Entry_Category}
        </span>

        {/* <p className="text-xs pt-1.5 text-neutral-700 font-light"> <FontAwesomeIcon icon={faClock} /> {e.createdAt.slice(0, 10).replaceAll('-', '/')} </p> */}
          <Link
            to={e.Entry_Category + '/' + e._id}
            className="hover:text-[#462053]"
            >
            <h3 className={`${isMobile || isTablet ? 'hyphenate text-md font-bold py-1.5' : "hyphenate text-xl font-bold py-1.5"} text-gray-800`}> {e.Entry_Title}.</h3>
          </Link>


        <p className="mt-1 text-sm text-gray-600" id="card__preview-text">{e.Entry_Resume}</p>


      </div>)
    : 
    
    arr.map((e) =>  <div className="md:w-2/4 h-auto p-1.5 mb-36" key={e}>
    <div className="w-100 h-[200px] bg-slate-700/95 animate-pulse">
      <div className="relative top-48">
        <div className="relative w-1/6 h-3 top-4 bg-slate-600"></div>
        <div className="w-11/12 h-6 mt-6 mb-1 bg-slate-600"> </div>
        <div className="h-4 mt-1 w-100 bg-slate-600"></div>
        <div className="h-4 mt-1 bg-slate-600"></div>
        <div className="h-4 mt-1 bg-slate-600"></div>
        <div className="w-64 h-4 mt-1 bg-slate-600"></div>
      </div>
    </div>
  </div>
  ) 
    }

  </section>
    </section>
  )
};