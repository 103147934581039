import { faClock, faHome, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer.jsx";
import HeaderTest from "./../Home/HeaderTest.jsx";
import Publicidades from "../Ads/Publicidades.jsx";

/*Imagenes de Publicidades*/
import CalfPubli from "./../../assets/Calf/300-x-600--SIGAMOS-HACIENDO.jpg";
import Banner from "./../../assets/data/banner.gif";

export default function SearchPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  const [data, setData] = useState([]);

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPosts = async (page) => {
    try {
      const response = await axios.get(`http://localhost:3000/posts/${category}?page=${page}&limit=10`);
      setPosts(response.data.posts);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error al obtener las publicaciones', error)
    }
  }

  useEffect(() => {
    if (query) {
      const getData = async () => {
        try{
          const result = await axios.get(`${process.env.REACT_APP_BUSCAR}api/busqueda?query${query}`);
          console.log(result.data)
          setData(result.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      getData();
    }
  }, [query]);

  const mapped = data.map((e) => e);

    const filterTitle = mapped.filter((obj) =>
    Object.values(obj).some((val) => val.includes(output))
  );
  filterTitle.reverse();

  // const titleFilter = mapped.filter((e) => e.Entry_Title.includes(postCategory));

  // const bodyFilter = mapped.filter((e) => e.Entry_Body === postCategory);

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <HeaderTest />

        <section className="flex-grow" id="container">
          <Publicidades imagen={Banner} />
          <p className="text-sm text-neutral-400">
            <Link to="/" className="duration-200 hover:text-purple-700">
              <FontAwesomeIcon icon={faHome} /> Inicio
            </Link>{" "}
            / Resultado de búsqueda para: {query}
          </p>

          <p className="p-2 text-2xl font-medium">Resultados de búsqueda para: <i className="font-bold decoration-none">{ query }</i></p>

          <div className="flex">
            <div className="w-full md:w-3/4">
              {data.length > 0 ? (
                data.map((e) => (
                  <div key={e._id} className="flex w-full p-3">
                    <img
                    loading="lazy"
                      className="object-cover duration-150 delay-100 filter hover:brightness-105 md:h-60 w-60 saturate-150"
                      src={e.Entry_Featured_Image}
                      alt="Imagen"
                    />

                    <div>
                      <span className="relative flex w-full h-5 left-5">
                        <FontAwesomeIcon className="text-xs bg-white border-2 border-neutral-400 rounded-3xl text-neutral-400" icon={faUser} />
                        <p className="px-1 text-xs font-light text-neutral-400">{e.Author_Name}</p>
                        <FontAwesomeIcon className="text-xs pt-0.5 ml-3 text-neutral-400 font-light" icon={faClock} />
                        <p className="px-1 text-xs font-light text-neurtal-400">{e.createdAt.slice(0, 10).replaceAll('-', '/')}</p>
                      </span>

                      <span>
                        <a href={e.Entry_Category + "/" + e._id} className="duration-200 hover:text-purple-600 w-100">
                          <h2 className="w-auto px-3 font-sans font-bold text-md md:text-2xl md:w-100">{e.Entry_Title}</h2>
                        </a>
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="p-2 text-xl">No se encontraron resultados</p>
              )}
            </div>

            <section className="hidden w-1/3 mb-20 text-white md:block">
              <div className="sticky text-white w-100 top-20">
                <a href="https://www.cooperativacalf.com.ar" target="_blank" rel="noopener noreferrer">
                  <img loading="lazy" src={CalfPubli} className="w-100" alt="Publicidad Calf" />
                </a>

              </div>
            </section>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
