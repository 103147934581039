import { hydrateRoot, createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { StrictMode } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';


import Player from './components/Player.jsx';


/*<----- Public Routes Components ----->*/
import Login from './components/Home/Login.jsx';
import Category from './Pages/Categories/Category';
import PostContainer from './Pages/PostContainer/PostContainer';
/*<----- Admin Routes Components ----->*/
import Dashboard from './components/Dashboard/Dashboard';
import Ui from './Pages/Admin/Ui';
import Post from './Pages/Admin/Posts';
import EditPostContainer from './components/Admin/EditPostContainer';
// import Search from './Pages/Search'
/*<----- Other Components ----->*/
import SearchPage from './components/Search/SearchPage';
import Inmobiliarias from './Pages/Inmobiliarias/Inmobiliarias';
import InmobiliariasContainer from './Pages/Inmobiliarias/InmobiliariasContainer';
import ScrollToTop from './Helpers/ScrollToTop.jsx';
import Weather from './components/Weather/Weather.jsx';
import NotFoundPage from './components/notFoundPage.jsx';
import RadioPlayer from './components/Player/RadioPlayer.jsx';



const container = document.getElementById('root');
const app = (
    <StrictMode>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<App/>}></Route>
          <Route path="/search/:category" element={<Category/>}></Route>
          <Route path="/busqueda" element={<SearchPage/>}></Route>
          <Route path="/Inmobiliarias" element={<Inmobiliarias/>}></Route>
          <Route path="/Inmobiliarias/:id" element={<InmobiliariasContainer/>}></Route>

          {/* USER ROUTES */}
          <Route path="/account/login" element={<Login/>}></Route>
          <Route path="/admin" element={<Ui/>}></Route>
          <Route path="/admin/edit/:post" element={<EditPostContainer/>}></Route>
          <Route path="/Publicaciones/Nueva/Crear" element={<Post/>}></Route>

          {/* CONTENT ROUTES */}
          <Route path="/Dashboard" element={<Dashboard/>}></Route>

          {/* POST ROUTE */}
          <Route exact path="/:category/:id" element={<PostContainer/>}></Route>
          <Route path="/Weather" element={<Weather/>}></Route>
          <Route path="/Player" element={<RadioPlayer/>}></Route>

          <Route element={<NotFoundPage/>} /> {/* Sin path, para capturar todas las rutas no encontradas */}

        </Routes>
      </BrowserRouter>
    </StrictMode>
)
if (container.hasChildNodes()) {
  hydrateRoot(container, app);
} else {
  createRoot(container).render(app)
}