import { Link } from 'react-router-dom';

export default function NotFoundPage () {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="font-bold text-purple-700 text-9xl">404</h1>
      <h2 className="mt-4 text-4xl font-semibold text-gray-800">Página no encontrada</h2>
      <p className="mt-2 text-lg text-gray-500">
        Lo sentimos, la página que estás buscando no existe.
      </p>
      <Link to="/">
        <button className="px-6 py-3 mt-6 text-lg text-white transition duration-300 bg-purple-700 rounded-lg hover:bg-purple-800">
          Volver al inicio
        </button>
      </Link>
    </div>
  );
};
