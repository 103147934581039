import OpenGraph from '../../components/OpenGraph/OpenGraph.jsx';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { faPrint, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// import useTitle from '../../Helpers/useTitle';
import FacebookComments from './../../components/FacebookComments/FacebookComments.jsx';

import ASFALTO from './../../assets/muni2024/BANNER-WEB-ASFALTO-995x200.gif'
import ASFALTOM from './../../assets/muni2024/BANNER-WEB-ASFALTO-445x200.gif'

import LOTEOS from './../../assets/muni2024/BANNER-WEB-LOTEOS-995X200.gif'
import LOTEOSM from './../../assets/muni2024/BANNER-WEB-ASFALTO-445x200.gif'

import PASEO from './../../assets/muni2024/BANNER-WEB-PASEO-995x200.gif'
import PASEOM from './../../assets/muni2024/BANNER-WEB-PASEO-445x200.gif'

import POLO from './../../assets/muni2024/BANNER-WEB-POLO-995x200.gif'
import POLOM from './../../assets/muni2024/BANNER-WEB-POLO-445x200.gif'

  function getRandomBanner() {
    const banners = [ASFALTO, LOTEOS, PASEO, POLO];
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  }

  function getRandomBannerMobile() {
    const banners = [ASFALTOM, LOTEOSM, PASEOM, POLOM,];
    const randomInxed = Math.floor(Math.random() * banners.length);
    return banners[randomInxed];
  }

function PostDetail(props) {
  const [posts, setPosts] =  useState([]);
  const [entretenimiento, setEntretenimiento] =  useState([]);
  const [emprender, setEmprender] =  useState([]);
  const [streaming, setStreaming] =  useState([]);
  
  const { myObj } = props;
  const navigate = useNavigate()
  
  const SHARE_URL = `https://www.voxdiario.com/${myObj["Entry_Category"]}/${myObj["_id"]}`
  const url = "axios.get(`${process.env.REACT_APP_POST_BY_ID}/${id}`)";
  const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    navigate(0)
  }
  const randomNumber = Math.floor(Math.random() * 3);
  
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  
  useEffect(() => {
    const getPosts = async () => {
      const result = await axios.get(process.env.REACT_APP_POSTS_API_URL);
      setPosts(result.data);
    }

    // const getStreaming = async () => {
    //   const result = await axios.get(`${process.env.REACT_APP_CONTENT}getStreaming`);
    //   setStreaming(result.data);
    // }
    
    // const getEmprender = async () => {
    //   const result = await axios.get(`${process.env.REACT_APP_CONTENT}getEmprender`);
    //   setEmprender(result.data);
    // }

    const getEntretenimiento = async () => {
      const result = await axios.get(`${process.env.REACT_APP_CONTENT}getEspectaculos`);
      setEntretenimiento(result.data);
      console.log(result)
    }
    
    getPosts();
    // getStreaming();
    // getEmprender();
    getEntretenimiento();
  }, [])

  /* POST ENTRY */
    /* Recomendados */
    const mismaCategoria = posts.map((e => e)).filter((e => e.Entry_Category === myObj["Entry_Category"])).reverse().slice(0, 4);

    /* Entretenimiento */
    const Entretenimiento = entretenimiento.map((e => e)); 
    // const Entretenimiento = posts.map((e => e)).filter((e => e.Entry_Category === 'Espectáculos')).reverse().slice(0, 4);

    /*  Streaming  */
    const Streaming = posts.map((e => e)).filter((e => e.Entry_Category === 'Streaming')).reverse().slice(0, 4);

    /*  Emprender  */
    const Emprender = posts.map((e => e)).filter((e => e.Entry_Category === 'Emprender')).reverse().slice(0, 4);
  

  const TITLE = myObj.Entry_Title;
  const IMAGE = myObj.Entry_Featured_Image;
  const DESCRIPTION = myObj.Entry_Resume;

  return (
    <section className=''>

       
      <section className='flex'>
         <div className='bg-white w-100' id="printablediv">
          <h2 className='py-2 text-xl font-bold md:text-5xl'>{myObj["Entry_Title"]}</h2>
          <h3 className='text-md md:text-xl text-[#777777] py-2'> {myObj["Entry_Resume"]}</h3>


          {/* POST INFO WIDGET */}
          <ul className='hidden gap-2 py-3 w-100'>
            <li>{myObj["Author_Name"]}</li>
            <li>{myObj["createdAt"]}</li>
            <li>Tiempo de lectura</li>  
          </ul>

          <div>
          <img loading="lazy" src={myObj["Entry_Featured_Image"]} />

          {/* SHARE POST WIDGET */}
          <ul className='flex justify-between p-3 md:w-2/3'>
            <span className='flex justify-start my-auto'>
              <FontAwesomeIcon icon={faShareNodes} className="p-2 text-black"/>
              <p className='py-2 text-xs'>Compartir</p>
            </span>
            <ul className='flex'>
                <li className='p-1'>
                  <FacebookShareButton url={SHARE_URL} hashtag='#VoxDiario'>
                    <FacebookIcon className='rounded-full' size={'40px'}/>
                  </FacebookShareButton>
                </li>
                <li className='p-1'>
                  <TwitterShareButton url={SHARE_URL}>
                    <TwitterIcon className='rounded-full' size={'40px'}/>
                  </TwitterShareButton>
                </li>
                <li className='p-1'>
                  <WhatsappShareButton url={SHARE_URL} title={myObj["Entry_Title"]}>
                    <WhatsappIcon size={'40px'} className="rounded-full"/>
                  </WhatsappShareButton>
                </li>
                <li>
                  <button className='p-2 w-[40px] rounded-full m-1 bg-neutral-700 text-white hover:bg-neutral-600'
                    onClick={Print}>
                  <FontAwesomeIcon icon={faPrint}/>
              </button>
                </li>
              </ul>
          </ul>
          <article className='w-100'>
            <p className='' 
            dangerouslySetInnerHTML={ { __html: myObj["Entry_Body"]}}></p>
          </article>
          </div>
        </div>


      {/* PUBLICIDAD DENTRO DE LA NOTA */}
        <div>

        </div>
        </section>



      {/* COMPARTIR AL PIE DE NOTA  */}

      <div>
        <ul className='flex w-2/3 p-3'>
            <span className='flex justify-start my-auto'>
              <FontAwesomeIcon icon={faShareNodes} className="p-2 text-black"/>
              <p className='py-2 text-xs'>Compartir</p>
            </span>
            <span className='flex ml-10'>

              <ul className='flex'>
                <li className='p-1'>
                  <FacebookShareButton url={SHARE_URL} hashtag='#VoxDiario'>
                    <FacebookIcon className='rounded-full' size={'40px'}/>
                  </FacebookShareButton>
                </li>
                <li className='p-1'>
                  <TwitterShareButton url={SHARE_URL}>
                    <TwitterIcon className='rounded-full' size={'40px'}/>
                  </TwitterShareButton>
                </li>
                <li className='p-1'>
                  <WhatsappShareButton url={SHARE_URL} title={myObj["Entry_Title"]}>
                    <WhatsappIcon size={'40px'} className="rounded-full"/>
                  </WhatsappShareButton>
                </li>
                <li>
                  <button className='p-2 w-[40px] rounded-full m-1 bg-neutral-700 text-white hover:bg-neutral-600'
                    onClick={Print}>
                  <FontAwesomeIcon icon={faPrint}/>
              </button>
                </li>
              </ul>

            </span>
          </ul>
        </div>

      {/* PUBLICIDAD EN EL POST */}
      <div className='h-auto w-100'>
        <a href="https://www.neuquencapital.gov.ar/" target='_blank'>
          <img loading="lazy" src={isDesktop ? getRandomBanner(): getRandomBannerMobile()} className='my-2 w-100'/>

          {/* {
            randomNumber / 2 
            ? <img src={isDesktop ? asfaltoPC : asfaltoMobile } className='my-2 w-100'/>
            : <img src={isDesktop ? loteoPC : loteoMobile } className='my-2 w-100'/>
          } */}
          
        </a>
      </div>

      {/* COMENTARIOS DE FACEBOOK  */}

      <div className='h-auto mb-10 w-100'>
        <FacebookComments category={myObj["Entry_Category"]} link={myObj["Entry_Title"]}/>
      </div>



      {/* RELACIONADOS */}

        {/* <div className='pt-6 w-max'>
            <h2 className='text-xl font-bold uppercase'>Temas relacionados</h2>
            <br></br>
            <span className='flex'>
              <Link to="#" className='px-3 uppercase font-semibold  outline-3 outline outline-[#604595]'>Calf</Link>
              <Link to="#" className='px-3 uppercase font-semibold mx-3 outline-3 outline outline-[#604595]'>Ciudad</Link>
            </span>
        </div> */}

        <br></br>
        <br></br>

        {/* <div className='w-max'>
            <h2 className='text-xl font-bold uppercase'>Notas relacionadas</h2>
            <hr className='text-[#604595]'/>
            <div>
              <span className='px-6  bg-[#605495]'>1</span>
              <span className='px-6 mx-4 bg-[#605495]'>2</span>
            </div>
        </div> */}

        {/* <br></br>
        <br></br> */}

        <div className='w-auto'>
          <span className='flex w-100'>
            <a href={'search/' + myObj["Entry_Category"]}>
              <h2 className='text-xl font-bold uppercase w-max'>Más de {myObj["Entry_Category"]}</h2>
            </a>
            <div className='max-w-6xl w-screen my-2.5 relative left-2 h-1 bg-[#604595]'></div>
          </span>
            <div className={isMobile || isTablet ? 'w-100 py-3' : 'md:flex w-100 py-3'}>
              {mismaCategoria.map((e => 
              <div className='h-auto py-2 w-100 mr-2.5 shadow-2xl' key={e._id}>

                <a href={`/${e.Entry_Category}/${e._id}`}>
                  <img loading="lazy" src={e.Entry_Featured_Image} className="object-cover h-[200px] w-100"/>
                </a>

                <a href={"/search/" + e.Entry_Category}>
                  <h3 className='py-1 px-2 font-bold text-[#604595]'>{e.Entry_Category}</h3>
                </a>

                <a href={`/${e.Entry_Category}/${e._id}`}>
                  <h2 className='w-auto p-2 font-bold text-md'>{e.Entry_Title}</h2>
                </a>

              </div>
              ))}

            </div>
        </div>


        <br></br>
        <br></br>
{/* STREAMING */}
        <div className='w-auto'>
          <span className='flex w-100'>
            <a href="search/streaming">
              <h2 className='text-xl font-bold uppercase'>Streaming</h2>
            </a>
            <div className='w-100 my-2.5 relative left-2 h-1 bg-[#604595]'></div>
          </span>
            <div className={isMobile || isTablet ? 'w-100 py-3' : 'md:flex w-100 py-3'}>
            {Streaming.map((e => 
              <div className='h-auto py-2 w-100 mr-2.5 shadow ' key={e._id}>

                <a href={`/${e.Entry_Category}/${e._id}`}>
                  <img loading="lazy" src={e.Entry_Featured_Image} className="object-cover h-[200px] w-100"/>
                </a>

                <a href={'search/Streaming'} className="hover:underline decoration-[#604595]">
                  <h3 className='py-1 px-2 font-bold text-[#604595]'>{e.Entry_Category}</h3>
                </a>

                <a href={`/${e.Entry_Category}/${e._id}`}>
                  <h2 className='w-auto p-2 font-bold text-md'>{e.Entry_Title}</h2>
                </a>
              </div>
              ))}

            </div>
            <div className='p-2'>
              <Link
                to={'/search/streaming'}
                className="font-extralight uppercase text-sm text-[#604595] hover:underline hover:text-[#604595] underline-offset-4">
                    Ver más
              </Link>
            </div>
        </div>

        <br></br>
{/* EMPRENDER */}
        <div className='w-auto'>
          <span className='flex w-100'>
            <a href={'/search/Emprender'} className="hover:underline hover:text-black">
              <h2 className='text-xl font-bold uppercase'>Emprender</h2>
            </a>
            <div className='w-100 my-2.5 relative left-2 h-1 bg-[#604595]'></div>
          </span>
            <div className={isMobile || isTablet ? 'w-100 py-3' : 'md:flex w-100 py-3'}>
            {Emprender.map((e => 
              <div className='h-auto py-2 w-100 mr-2.5 shadow ' key={e._id}>
                
                <a href={`/${e.Entry_Category}/${e._id}`}>
                  <img loading="lazy" src={e.Entry_Featured_Image} className="object-cover h-[200px] w-100 object-top"/>
                </a>

                <a href="/search/Emprender" className='hover:underline decoration-[#604595]'>
                  <h3 className='py-1 px-2 font-bold text-[#604595]'>{e.Entry_Category}</h3>
                </a>

                <a href={`/${e.Entry_Category}/${e._id}`}>
                  <h2 className='w-auto p-2 font-bold text-md'>{e.Entry_Title}</h2>
                </a>
              </div>
              ))}

            </div>
            <div className='p-2'>
              <Link
                to={'/search/emprender'}
                className="font-extralight uppercase text-sm text-[#604595] hover:underline hover:text-[#604595] underline-offset-4">
                  Ver más
              </Link>
            </div>
        </div>

        <br></br>

        {/* <div className='w-auto'>
          <div className='flex w-100'>
            <h2 className='w-1/5 text-xl font-bold uppercase'>Medio Ambiente</h2>
            <div className='w-100 my-2.5 relative left-2 h-1 bg-[#604595]'></div>
          </div>
            <div className='flex'>
            {mismaCategoria.map((e => 
              <div className='h-auto w-auto mr-2.5 shadow ' key={e._id}>
                <img src={e.Entry_Featured_Image} className="object-cover h-[200px] w-100"/>
                <h3 className='py-1 px-2 font-bold text-[#604595]'>{e.Entry_Category}</h3>
                  <h2 className='w-[250px] text-md font-bold py-1 px-2'>{e.Entry_Title}</h2>
              </div>
              ))}

            </div>
              <Link
                to={'/' + element.Entry_Category}
                className="font-extralight uppercase text-sm text-[#604595] hover:underline hover:text-[#604595] underline-offset-4">
                  Ver más
              </Link>
        </div> */}
        
        {/* <br></br>
        <br></br> */}
            {/*
        <div className='w-auto'>
          <div className='flex w-100'>
            <a href={'/search/Espectáculos'} className="hover:underline hover:text-black">
              <h2 className='text-xl font-bold uppercase'>Espectáculos</h2>
            </a>
            <div className='w-100 my-2.5 relative left-2 h-1 bg-[#604595]'></div>
          </div>
            
            <div className={isMobile || isTablet ? '' : 'flex'}>
            {Entretenimiento.map((e => 
              <div className='h-auto w-100 py-2 mr-2.5 shadow ' key={e._id}>
                
                <a href={e.Entry_Category + '/' + e._id}>
                  <img src={e.Entry_Featured_Image} className="object-cover h-[200px] w-100 object-top"/>
                </a>

                <a href="/search/Espectáculos" className='hover:underline decoration-[#604595]'>
                  <h3 className='py-1 px-2 font-bold text-[#604595]'>{e.Entry_Category}</h3>
                </a>

                <a href={e.Entry_Category + '/' + e._id} className="w-100">
                  <h2 className='w-auto p-2 font-bold text-md'>{e.Entry_Title}</h2>
                </a>

              </div>
              ))}

            </div>

            <div className='p-2'>
              <Link
                to={'/search/Espectáculos'}
                className="font-extralight uppercase text-sm text-[#604595] hover:underline hover:text-[#604595] underline-offset-4 p-1">
                  Ver más
              </Link>
            </div>
        
        </div>
    */}
        <br></br>
      </section>
  )
}

export default PostDetail