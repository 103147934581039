import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

function Economia() {
  const [data, setData] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get(process.env.REACT_APP_DEPORTES);
      setData(result.data);
    }
    getData();
  }, [])


  return (
    <section className='mt-4 w-100' id="container">
      <div id="wrapper-category" className='w-[180px] md:h-12 bg-green-600'>
        <h2 className='relative flex justify-center p-2 text-xl font-bold text-white left-2'>Deportes</h2>
      </div>

      <section className='md:flex md:gap-4'>

      {data ? data.slice(0, 3).map((e) => 
      <div key={e._id} className={isDesktop ? "my-2 md:w-2/6 shadow rounded p-3 h-auto" : 'shadow my-3 w-100 p-3 rounded'}>

        <Link to={e.Entry_Category + '/' + e._id}>
          <img loading="lazy" src={e.Entry_Featured_Image} className="object-cover h-56 w-100" alt={e.Entry_Title + '-img'}/>
        </Link>

        <span className='relative float-right p-2 text-sm text-white bg-green-600 -top-14 -left-3'>
          <p>{e.Entry_Category}</p>
        </span>
        {/* <p className="text-xs text-neutral-400 py-1.5">  <FontAwesomeIcon icon={faClock}/>  {e.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
        
          <h2 className={`font-bold ${isDesktop ? 'text-xl pt-1.5 pb-0.5' : 'text-md py-1'}`}>
            <Link  className="hover:text-[#604595] w-100" to={e.Entry_Category + '/' + e._id}>
              {e.Entry_Title}
            </Link>
          </h2>

        <h3 className={isDesktop ? 'text-md ' : 'text-sm my-1'} id="card__preview-text">{e.Entry_Resume}</h3>

        
          <Link to={e.Entry_Category + '/' + e._id}>
          <button className='text-white bg-green-600 p-1.5 rounded mt-2 text-sm'>
            <p className="hover:text-blue-700">Leer Más...</p>
          </button>
          </Link>
        

      </div>) : null }
        </section>
    </section>
  )
}

export default Economia