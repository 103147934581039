/* MODULES */
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

/* ASSETS && COMPONENTS */
import HeaderTest from './../../components/Home/HeaderTest'
import InmobiliariasDetail from './../../Pages/Inmobiliarias/InmobiliariasDetail';
import BannerLarge from './../../assets/Inmobiliarias/bannerGrande.svg';
import BannerSmall from './../../assets/Inmobiliarias/bannerChico.svg';
import Footer from '../../components/Footer/Footer';

function InmobiliariasContainer() {
  const [products, setProducts] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetch(process.env.REACT_APP_POSTS_API_URL)
      .then(response => response.json())
      .then(data => {
        setProducts(data);
      })
      .catch(err => console.log(err));
  }, []);

  const mapped = products.map(e => e);
  mapped.reverse();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <section className='max-h-max'>
      <HeaderTest/>
      <div className='container-xl'>
        <div className='relative flex justify-center mb-3 mt-36 '>
          <img loading="lazy" src={isMobile ? BannerSmall : BannerLarge} className="object-cover w-auto max-h-max" alt={'Ghiggia Beneito Asesoría Inmobiliaria'} />
        </div>
      </div>

      <section className='h-full min-h-screen container-xl'>
        {products ? (
          products.map(element => (
            element._id === id && (
              <section key={element._id} className="h-full ">
                <ul className={isDesktop ? "flex text-neutral-400 text-sm px-12" : "flex text-neutral-400 text-sm"}>
                  <li>
                    {" "}
                    <Link to="/" className='hover:text-[#604594]'>
                      {" "}
                      <FontAwesomeIcon icon={faHome} className="text-sm" /> Inicio{" "}
                    </Link>{" "}
                    /
                  </li>{" "}
                  <li className="px-1">
                    {" "}
                    <Link to={'/' + element.Entry_Category} className="hover:text-[#604594]">
                      {" "}
                      {element.Entry_Category}{" "}
                    </Link>{" "}
                  </li>{" "}
                  {!isMobile && <li className="hidden  md:flex"> / {element.Entry_Title} </li>}
                </ul>

                <InmobiliariasDetail key={element._id} element={element} />
              </section>
            )
          ))
        ) : <h2>Cargando..</h2>}
      </section>

      <Footer/>
    </section>
  )
}

export default InmobiliariasContainer;
