import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import data from './../../assets/data.json'

export default function Carousel(props) {
  const {carousel} = props;

  /* Publicaciones */
  // const [carousel, setCarousel] = useState([]);
  const map = carousel && carousel.map((e) => e);
  const secondMap = carousel.map((e) => e);
  /* <----- Portada -----> */
  let portada = map.pop();
  /* <----- Grid Items -----> */
  let gridItems = secondMap.reverse().slice(1, 5);
  let arr = [0, 1, 2, 3];

  // useEffect( () => {
  //   const getData = async () => {
  //     const result = await axios.get(process.env.REACT_APP_CAROUSEL_URI);
  //     setCarousel(result.data.reverse());
  //   }
  //   getData();
  // }, [])
  return (
    <section className={"h-auto relative"}>
      {/* PORTADA */}
      <div className="flex flex-wrap w-full h-full">
        <div className="flex w-full h-auto border-2 md:w-1/2">
          {portada ?  (
            <Link
              to={
                portada.Entry_Category === "Inmobiliarias"
                  ? "/Inmobiliarias/" + portada._id
                  : portada.Entry_Category + '/' + portada._id
              }
              className="text-white group"
            >
              <div className="relative h-full">
                <img
                  loading="lazy"
                  src={portada.Entry_Featured_Image}
                  className="w-full h-full md:h-full object-fit brightness-50 md:brightness-75"
                  alt={portada.Entry_Title + "-img"}
                />
                <span className="absolute w-full top-4 sm:top-12 xl:top-28 2xl:top-36 lg:top-40">
                  {/* <p className="px-4 font-medium text-md">
                    {portada.createdAt.slice(0, 10).replaceAll("-", "/")}
                  </p> */}
                  <h3 className="px-4 py-2 font-bold text-md md:text-3xl" id="card__preview-text">
                    {portada.Entry_Title}
                  </h3>
                  <p className="hidden px-4 text-sm md:block" id="card__preview-text">
                    {portada.Entry_Resume}
                  </p>
                </span>
              </div>
            </Link>
          )  : (
            <div className=" w-100 bg-slate-700/95 animate-pulse h-96">
              <section className="relative h-full w-100 top-40 md:top-50">
                <div className="relative h-2 mx-4 rounded-lg w-25 md:h-4 bg-slate-600"></div>
                <div className="relative w-11/12 h-10 mx-4 my-4 rounded-lg bg-slate-600"></div>
                <div className="relative w-11/12 h-5 mx-4 rounded-lg bg-slate-600"></div>
                <div className="relative w-11/12 h-5 mx-4 my-2 rounded-lg bg-slate-600"></div>
              </section>
            </div>
          ) }
        </div>

        <div className="flex flex-wrap w-full h-auto md:w-1/2">
          {gridItems.length > 0 ? (
            gridItems.map((e, i) => (
              <div className="p-0.5 h-1/2 w-1/2" key={i}>
                <Link
                  to={e.Entry_Category === 'Inmobiliarias' ? '/Inmobiliarias/' + e._id : e.Entry_Category + '/' + e._id}
                  className="text-white hover:text-white group"
                >
                  <div className="relative h-full md:backdrop-brightness-150">
                    <img
                      loading="lazy"
                      src={e.Entry_Featured_Image}
                      className="w-full h-full object-fit"
                      alt={e.Entry_Title + "img"}
                    />
                    <div className="absolute top-0 duration-700 h-100 w-100 backdrop-brightness-75 md:bg-black/10 hover:backdrop-brightness-90 md:group-hover:bg-black/70">
                      <div className="absolute duration-700 top-5 md:text-sm md:top-12 lg:top-14 xl:top-14 2xl:top-20 lg:group-hover:-translate-y-12 2xl:group-hover:-translate-y-14 md:group-hover:-translate-y-10">
                        {/* <p className="px-2">
                          {e.createdAt.slice(0, 10).replaceAll("-", "/")}
                        </p> */}
                        <h3
                          className="px-2 font-sans antialiased font-semibold md:group-hover:underline"
                          id="card__preview-title"
                        >
                          {e.Entry_Title}
                        </h3>
                        <p
                          className="hidden px-2 font-medium text-transparent duration-700 md:block group-hover:text-white"
                          id="card__preview-text"
                        >
                          {e.Entry_Resume}...
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="flex flex-wrap w-full h-full">
              {arr.map((e) => (
                <section
                  className="flex justify-center w-1/2 border-2 bg-slate-700/95 animate-pulse h-1/2"
                  key={e}
                >
                  <section className="relative h-4/6 top-1 w-100">
                    <div className="w-12 h-1 mx-4 rounded-lg md:h-2 bg-slate-600"></div>
                    <div className="w-5/6 h-4 mx-4 my-2 rounded-lg md:h-6 bg-slate-600"></div>
                    <div className="w-5/6 h-2 mx-4 rounded-lg md:h-4 bg-slate-600"></div>
                    <div className="w-5/6 h-2 p-2 mx-4 my-2 rounded-lg md:h-4 bg-slate-600"></div>
                  </section>
                </section>
              ))}
            </div>
          ) }
        </div>
      </div>
    </section>
  );
};
