import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import JoditEditor from 'jodit-react';
export default function Posts() {
  /*----- // -----*/
  const navigate = useNavigate();
  /*----- // -----*/
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const capitalize =  user.userData.username.replace(/^\w/, c => c.toUpperCase())
  const isMobile = useMediaQuery({ maxWidth: 768 });

  /*----- Posts Category -----*/
  const options = [ 
    { label: 'Anticipación Política', value: 'Anticipacion Politica'},
    { label: 'Deportes', value: 'Deportes'},
    { label: 'Economia', value: 'Economia'},
    { label: 'Emprender', value: 'Emprender'},
    { label: 'Energia', value: 'Energia'},
    { label: 'Espectaculos', value: 'Espectaculos'},
    { label: 'Inmobiliarias', value: 'Inmobiliarias'},
    { label: 'Los40', value: 'Los40'},
    { label: 'Politica', value: 'Politica'},
    { label: 'Policiales', value: 'Policiales'},
    { label: 'Sociedad', value: 'Sociedad'},
    { label: 'Streaming', value: 'Streaming'},
    { label: 'Tecnologia', value: 'Tecnologia'}
  ];

  /*----- Handling Events -----*/
  const [title, setTitle] = useState('');
  const [resume, setResume] = useState('');
  const [image, setImage] = useState('');
  const [body, setBody] = useState('');
  const [category, setCategory] = useState('Politica');
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  /*<---- // ---->*/
  const handleTitle = (e)  => { e.preventDefault(); setTitle(e.target.value);};
  const handleResume = (e) => { e.preventDefault(); setResume(e.target.value);};
  const handleImage = (e)  => { e.preventDefault(); setImage(e.target.value);};
  const handleSubmit = (e) => { setStepOne(false)};
  const handlePreview = (e) => { e.preventDefault();
    setStepTwo(true);};
  /*<---- // ---->*/
  // const [tags, setTags] = useState('');
  // const handleTags = (e) => { let i = 0; const newTag = { label: e.target.value, id: i}; i++; setTags([...tags, newTag]);}

  /*<----- POST HANDLER ----->*/
  const handlePost = (e) => {
    e.preventDefault();
    var data = {
      Entry_Title: title,
      Entry_Resume: resume,
      Entry_Body: body,
      Entry_Featured_Image: image,
      Entry_Category: category,
    }
    fetch(process.env.REACT_APP_POSTS_API_URI, {
      method: 'POST',
      mode: 'cors',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.token
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      console.log('response', response);
      navigate(0);
      if (response.status === 200) {
        alert('Aprobado');
      }
    })
    .catch(e => {
      console.log('e', e);
    });
  }



  /* RICH TEXT HANDLER */
  const editor = useRef(null);
  const config = { readonly: false, height: 700,  editorCssClass: false};

  return (
    <section className={isMobile ? 'relative top-60' : ''}>
      <div>
        <form className={isMobile ? 'relative w-100' : 'w-100 flex justify-around my-4 border mt-10'}>
          {stepOne === true ? (
          <div className='text-center'>
{/* TITLE */}
            <label className='p-2 text-xl' htmlFor='title'>
              <strong> <h3>Título</h3></strong>
            </label>
            <div>
              <input type="text" required className={`block w-[500px] p-2 py-1.5 text-base font-normal text-gray700 bg-white bg-clip-pádding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-700 focus:outline-none ${isMobile ? 'w-100' : 'w-[500px]'}`} name="title" placeholder="Escriba un titulo"
              onChange={handleTitle}/>
            </div>
{/* RESUME */}
            <label className='p-2 text-xl' htmlFor="resume">
              <strong> <h3>Resumen</h3> </strong>
            </label>
            <div>
            <input type="text" required className={`block w-[500px] p-2 py-1.5 text-base font-normal text-gray700 bg-white bg-clip-pádding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-700 focus:outline-none ${isMobile ? 'w-100' : 'w-[500px]'}`} placeholder='Escriba un resumen' name="resume"
              onChange={handleResume}
            />
            </div>
{/* FEATURED IMAGE */}
          <label className='p-2 text-xl' htmlFor="featuredImage">
            <strong> <h3>Imagen destacada</h3></strong>
          </label>
          <input type="text" required className={`block w-[500px] p-2 py-1.5 text-base font-normal text-gray700 bg-white bg-clip-pádding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-700 focus:outline-none ${isMobile ? 'w-100' : 'w-[500px]'}`} name="featuredImage" placeholder="URL de la imagen" onChange={handleImage}/>
          <ul className=''>
{/* TAGS */}
            <li className={isMobile ? 'hidden' : 'p-2'}>
              <p className='block font-bold text-center text-md'>#Etiquetas</p>
              <span className='flex justify-center gap-3'>
                <input type="text" className='rounded-md border w-[150px] p-1 text-center' placeholder='#' onChange={null}/>
                <input type="text" className='rounded-md border w-[150px] p-1 text-center' placeholder='#' onChange={null}/>
                <input type="text" className='rounded-md border w-[150px] p-1 text-center' placeholder='#' onChange={null}/>
              </span>
            </li>
{/* CATEGORY */}
            <li>
              <span className=''>
                <label className='px-2 py-2 text-xl'> Categoría</label>
                <select className='p-1 border rounded' defaultValue={'Economía'} onChange={ (e) =>{ const selectedValue = e.target.value; setCategory(selectedValue)} }>
                  {options.map(item => {
                    return(<option key={item.label} value={item.value}>{item.value}</option>)
                  })}
                </select>
              </span>
            </li>
          </ul>
{/* BUTTON */}
            <button onClick={handleSubmit} className="inline-block p-4 px-6 py-2 my-4 font-medium leading-tight text-blue-400 uppercase transition duration-150 ease-in-out border-2 border-blue-400 rounded-full text-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring-0">
              Siguiente Paso
            </button>
          </div>
          ) :
          <div className={stepTwo === false ? "w-70" : 'hidden'}>
            <span className="flex justify-center">
                <h3 className="p-4 text-xl font-bold"> Cuerpo de la Entrada</h3>
            </span>

          <JoditEditor
              ref={editor}
              config={config}
              onBlur={newContent => setBody(newContent)}
              onChange={(newContent) => {}}
            />
            <div className="flex justify-center m-4">
              <button onClick={handlePreview} className="inline-block p-4 px-6 py-2 mt-3 font-medium leading-tight text-blue-400 uppercase transition ease-in-out border-2 border-blue-400 rounded-full outline-none text-md hover:bg-blue-400 hover:text-white focus:outline-none focus:ring-0 duration 150">
              Siguiente
              </button>
            </div>
          </div>
          }
          {stepTwo === true ? (
            <section className=''>
            <h3 className='p-2 font-sans text-xl font-bold'>Vista previa</h3>
            <article className='px-5 py-3 mx-4 border h-max'>
              <h2 className='p-2 font-sans text-3xl font-bold break-words w-100'>{title}</h2>
              <p className='p-2 font-sans text-xl text-neutral-500'>{resume}</p>
              <img loading="lazy" className='w-auto h-auto p-2' src={image}/>
              <p className='p-2 font-sans whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: body}}></p>
              <span className='flex justify-center gap-3 pt-4 pb-2'>
                <p className='p-2 px-2 font-sans font-bold border rounded-md w-max bg-zinc-50'># </p>
                <p className='p-2 px-2 font-sans font-bold border rounded-md w-max bg-zinc-50'># </p>
                <p className='p-2 px-2 font-sans font-bold border rounded-md w-max bg-zinc-50'># </p>
              </span> 
            </article>
            
            <div className='flex justify-center p-3'>
              <button onClick={handlePost} type="button" className="inline-block p-4 px-6 py-2 font-medium leading-tight text-blue-400 uppercase transition duration-150 ease-in-out border-2 border-blue-400 rounded-full text-md hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0">
                  Publicar
              </button>
            </div>
          </section>
          ) : null}
        </form>
      </div>
    </section>
  )
}
